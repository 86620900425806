import { useContext } from 'react'
import ConfigContext from '../../contexts/ConfigContext'
import styles from '../../styles/utility/PlatformWithIcon.module.css'
import questionMark from '../../assets/question-mark.svg'

const handleImageError = ({ target }) => {
  if (target.src.includes(questionMark.src)) {
    return
  }
  target.src = questionMark.src
}

export default function PlatformWithIcon({ platformName, linethrough = false }) {
  const { platforms } = useContext(ConfigContext)
  const { icon: iconSrc } = platforms[platformName]

  return (
    <div className={styles.platform}>
      <div className={styles.iconBlock}>
        <img src={iconSrc} alt="" aria-hidden="true" onError={handleImageError} />
      </div>
      <span
        style={{
          textDecoration: linethrough ? 'line-through' : 'none',
        }}
        className={styles.platformTitle}
      >
        {platformName}
      </span>
    </div>
  )
}
