export const setQueryParam = (key, value) => {
  const params = new URLSearchParams(window.location.search)
  if (value) {
    params.set(key, value)
  } else {
    params.delete(key)
  }

  // Construct the new URL
  const newUrl = `${window.location.pathname}${params.size > 0 ? `?${params}` : ''}`

  // Update the URL without adding a new entry to the history stack
  window.history.replaceState({}, '', newUrl)
}

export const getQueryParam = (key) => {
  const params = new URLSearchParams(window.location.search)

  return params.get(key)
}
