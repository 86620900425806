import styles from '../../styles/utility/Announcement.module.css'

export default function Announcement({ icon, text, link }) {
  return (
    <div className={styles.announcement}>
      <a className={styles.link} href={link} rel="noopener noreferrer">
        <span className={styles.icon}>{icon}</span>
        {text}
      </a>
    </div>
  )
}
