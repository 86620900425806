import { useState, useEffect, useRef, useContext } from 'react'
import Search from './components/Search'
import LandingPage from './pages/LandingPage'
import QueryPage from './pages/QueryPage'
import { getQueryParam } from './utils/queryString'
import styles from './styles/App.module.css'
import ConfigContext, { ConfigProvider } from './contexts/ConfigContext'

function App() {
  const platformConfig = useContext(ConfigContext)
  const [userInput, setUserInput] = useState('')
  const [typingState, setTypingState] = useState(false)
  const scrollPositionRef = useRef(0)

  useEffect(() => {
    const query = getQueryParam('q')
    if (query) {
      setUserInput(query)
    }
  }, [])

  return (
    <div className={styles.app}>
      <Search input={userInput} setInput={setUserInput} setTypingState={setTypingState} />
      {userInput ? (
        platformConfig ? (
          <QueryPage
            typingState={typingState}
            username={userInput}
            scrollPositionRef={scrollPositionRef}
          />
        ) : (
          <div>Loading...</div>
        )
      ) : (
        <LandingPage />
      )}
    </div>
  )
}

export default function AppWithConfig() {
  return (
    <ConfigProvider>
      <App />
    </ConfigProvider>
  )
}
