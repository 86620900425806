import { useEffect, useRef, useCallback, useState } from 'react'
import styles from '../styles/Search.module.css'
import debounce from '../utils/debounce'
import { setQueryParam } from '../utils/queryString'
import cross from '../assets/cross.svg'
import thunderbolt from '../../assets/thunderbolt.svg'

export default function Search({ input, setInput, setTypingState }) {
  const inputRef = useRef(null)
  const isOnFocusView = input.length > 0
  const [inputFocused, setInputFocused] = useState(false)

  const stopTyping = () => {
    setTypingState(false)
    setQueryParam('q', inputRef.current.value)
  }
  const debouncedStopTyping = useCallback(debounce(stopTyping, 800), [])

  const cleanUp = () => {
    debouncedStopTyping.clear()
    setTypingState(false)
    setInput('')
    inputRef.current?.focus()
    setQueryParam('q', '')
  }

  useEffect(() => {
    const handleKeyDown = (e) => {
      // if the input is already in focus, do nothing
      if (inputRef.current?.contains(document.activeElement)) {
        return
      }

      // if the key is not a letter, number, _, -, . or backspace, do nothing
      if (!/^[a-zA-Z0-9-_.]$/.test(e.key) && e.key !== 'Backspace') {
        return
      }

      // put the cursor to the end of the input
      const val = inputRef.current.value
      inputRef.current.value = ''
      inputRef.current.value = val

      inputRef.current?.focus()
    }

    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  return (
    <div className={`${styles.search} ${isOnFocusView ? styles['search--active'] : ''}`}>
      <div
        className={styles.search__header}
        onClick={cleanUp}
        aria-labelledby="header"
        aria-hidden={isOnFocusView}
      >
        <img id={styles.thunderbolt} src={thunderbolt.src} aria-hidden="true" />
        <h1 id="header">Instant Username Search</h1>
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault() // prevent the browser from submitting the form
        }}
      >
        <div
          className={`${styles['input-container']} ${
            inputFocused ? styles['input-container--focused'] : ''
          }`}
        >
          <input
            type="text"
            placeholder="Search username"
            enterKeyHint="search"
            value={input}
            ref={inputRef}
            onFocus={() => setInputFocused(true)}
            onBlur={() => setInputFocused(false)}
            onChange={(e) => {
              const niceInput = e.target.value.replace(/[^a-zA-Z0-9-_.]/g, '')
              if (niceInput == input) {
                // Do nothing if the input matches the state (no valid change).
                return
              }

              setInput(niceInput)
              if (e.target.value === '') {
                debouncedStopTyping.clear()
                stopTyping()
                return
              }

              setTypingState(true)
              debouncedStopTyping()
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                // blur the input to hide the keyboard on mobile
                e.target.blur()
              }
              if (e.key === 'Escape') {
                e.preventDefault() // prevent the browser from clearing the input
                e.target.blur()
              }
            }}
          />
          {input.length > 0 && (
            <button onClick={cleanUp} className={styles['clear-icon']} aria-label="Clear input">
              <img src={cross.src} alt="" />
            </button>
          )}
        </div>
      </form>
    </div>
  )
}
