import * as React from 'react'

// https://github.com/uidotdev/usehooks/blob/fa7f8bf3983cad6358c27e1a0547799b0865a168/index.js#L512
export default function useIntersectionObserver(options = {}) {
  const { threshold = 0, root = null, rootMargin = '0px' } = options
  const [entry, setEntry] = React.useState(null)

  const previousObserver = React.useRef(null)

  const customRef = React.useCallback(
    (node) => {
      if (previousObserver.current) {
        previousObserver.current.disconnect()
        previousObserver.current = null
      }

      if (node?.nodeType === Node.ELEMENT_NODE) {
        const observer = new IntersectionObserver(
          ([entry]) => {
            setEntry(entry)
          },
          { threshold, root, rootMargin },
        )

        observer.observe(node)
        previousObserver.current = observer
      }
    },
    [threshold, root, rootMargin],
  )

  return [customRef, entry]
}
