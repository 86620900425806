import { useState, useEffect } from 'react'

export default function useDynamicLoadingText(isLoading, isInViewport = false) {
  const text = 'Loading'
  const [loadingText, setLoadingText] = useState(text)

  useEffect(() => {
    let intervalId

    if (isLoading && isInViewport) {
      let dotCount = 0

      intervalId = setInterval(() => {
        dotCount = (dotCount + 1) % 4 // cycle through 0 to 3 dots
        setLoadingText(`${text}${'.'.repeat(dotCount)}`)
      }, 200)
    } else {
      setLoadingText(text)
    }

    return () => clearInterval(intervalId)
  }, [isLoading, isInViewport])

  return loadingText
}
