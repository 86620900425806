import { createContext, useContext, useState, useEffect } from 'react'

const StarContext = createContext()

export const StarProvider = ({ children }) => {
  const [starredPlatforms, setStarredPlatforms] = useState(() => {
    try {
      const platforms = localStorage.getItem('starred-platforms')
      return platforms ? JSON.parse(platforms) : {}
    } catch (error) {
      // Return an empty object if there's an error accessing localStorage.
      return {}
    }
  })

  useEffect(() => {
    try {
      localStorage.setItem('starred-platforms', JSON.stringify(starredPlatforms))
    } catch (error) {
      // Fails silently: starred platforms won't persist across sessions.
    }
  }, [starredPlatforms])

  return (
    <StarContext.Provider value={{ starredPlatforms, setStarredPlatforms }}>
      {children}
    </StarContext.Provider>
  )
}

export const useStar = (platformName) => {
  const { starredPlatforms, setStarredPlatforms } = useContext(StarContext)

  const starred = starredPlatforms[platformName] || false

  const toggleStar = () => {
    setStarredPlatforms((prev) => {
      const newStarredPlatforms = { ...prev }
      if (newStarredPlatforms[platformName]) {
        delete newStarredPlatforms[platformName]
      } else {
        newStarredPlatforms[platformName] = true
      }
      return newStarredPlatforms
    })
  }

  return [starred, toggleStar]
}

export default StarContext
