const debounce = (fn, delay, immediate = false) => {
  let timer = null
  let lastArgs = null

  const debounced = (...args) => {
    lastArgs = args
    if (timer === null && immediate) {
      fn(...args)
    }
    clearTimeout(timer)
    timer = setTimeout(() => {
      fn(...args)
      timer = null
    }, delay)
  }

  debounced.clear = () => {
    clearTimeout(timer)
    timer = null
    lastArgs = null
  }

  debounced.flush = () => {
    if (timer !== null) {
      clearTimeout(timer)
      fn(...lastArgs)
      debounced.clear()
    }
  }

  return debounced
}

export default debounce
