import styles from '../styles/LandingPage.module.css'
import Announcement from '../components/utility/Announcement'

export default function LandingPage() {
  return (
    <>
      <Announcement
        icon="🚀"
        text="Instant Username Blog is now live!"
        link="/blog"
      />
      <div className={styles.container}>
        <p>Instantly check username availability across multiple platforms as you type.</p>
      </div>
    </>
  )
}
