import { useState, useId } from 'react'
import ListItem from './ListItem'
import styles from '../styles/CategoryList.module.css'
import arrowUp from '../assets/arrow-up.svg'
import arrowDown from '../assets/arrow-down.svg'

const SHOW_MORE_THRESHOLD = 6

export default function CategoryList({
  categoryName,
  platformKeys = [],
  username,
  typingState,
  showMoreEnabled = true,
  starredCategory = false,
}) {
  const [expanded, setExpanded] = useState(false)
  const listId = useId()

  // Show "more" button if there's more than one extra item beyond the threshold. Because the button takes as much space as a single item.
  // In other words, if there's only one extra item, the button is redundant. Instead of showing the button, we can just show the extra item.
  const renderShowMore = showMoreEnabled && platformKeys.length > SHOW_MORE_THRESHOLD + 1

  return (
    <div className={`${styles.category} ${starredCategory ? styles.starred : ''}`}>
      <div className={styles['divider-text']}>
        <span aria-label={`Category: ${categoryName}`}>{categoryName}</span>
      </div>
      <ul className={styles.items} id={listId}>
        {platformKeys
          .slice(0, expanded || !renderShowMore ? platformKeys.length : SHOW_MORE_THRESHOLD)
          .map((item) => {
            return (
              <li key={item}>
                <ListItem platformName={item} username={username} typingState={typingState} />
              </li>
            )
          })}
      </ul>
      {renderShowMore && (
        <button
          className={styles['show-more']}
          onClick={() => {
            setExpanded((prev) => !prev)
          }}
          aria-label={expanded ? 'Show less' : 'Show more'}
          aria-expanded={expanded}
          aria-controls={listId}
        >
          <span>{expanded ? 'Less' : 'More'}</span>
          {expanded ? (
            <img src={arrowUp.src} alt="" aria-hidden="true" />
          ) : (
            <img src={arrowDown.src} alt="" aria-hidden="true" />
          )}
        </button>
      )}
    </div>
  )
}
