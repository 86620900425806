import { createContext, useState, useEffect } from 'react'

const ConfigContext = createContext()

export const ConfigProvider = ({ children }) => {
  const [platformConfig, setPlatformConfig] = useState()

  useEffect(() => {
    const MAX_ATTEMPTS = 20
    let attempts = 1

    const controller = new AbortController()
    const { signal } = controller
    const configURL = `${import.meta.env.PUBLIC_API_URL}/platforms.json`

    const fetchConfig = async () => {
      try {
        const response = await fetch(configURL, { signal })
        if (!response.ok) {
          throw new Error(`Network response was not ok. Attempt ${attempts} of ${MAX_ATTEMPTS}`)
        }
        const config = await response.json()
        setPlatformConfig(config)
      } catch (error) {
        if (error.name !== 'AbortError' && attempts < MAX_ATTEMPTS) {
          console.error(error.message)

          attempts++
          setTimeout(fetchConfig, 2000)
        } else {
          console.error('Fetch operation failed:', error.message)
        }
      }
    }

    fetchConfig()

    return () => {
      controller.abort()
    }
  }, [])

  return <ConfigContext.Provider value={platformConfig}>{children}</ConfigContext.Provider>
}

export default ConfigContext
