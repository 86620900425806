import Tooltip from './Tooltip'
import useDynamicLoadingText from '../../hooks/useDynamicLoadingText'
import useIntersectionObserver from '../../hooks/useIntersectionObserver'
import info from '../../assets/info.svg'
import styles from '../../styles/utility/PlatformResult.module.css'
import { platformResults, resultMessages } from '../../constants'

const getTooltip = (result) => {
  switch (result) {
    case platformResults.unknown:
      return (
        <Tooltip text="We're unable to verify this platform right now.">
          <img src={info.src} alt="" aria-hidden="true" className={styles.tooltipIcon} />
        </Tooltip>
      )
    default:
      return null
  }
}

const getColor = (result) => {
  switch (result) {
    case platformResults.available:
      return '#52c41a'
    case platformResults.error:
      return '#ff4d4f'
    default:
      return null
  }
}

export default function PlatformResult({ result }) {
  const [itemRef, entry] = useIntersectionObserver()

  const isLoading = result === platformResults.loading
  const loadingText = useDynamicLoadingText(isLoading, entry?.isIntersecting)

  return (
    <div ref={itemRef} className={styles.result}>
      {getTooltip(result)}
      <span
        style={{
          color: getColor(result),
          width: isLoading ? '6ch' : 'auto',
        }}
      >
        {isLoading ? loadingText : resultMessages[result]}
      </span>
    </div>
  )
}
