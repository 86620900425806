import { createContext, useRef, useEffect, useCallback } from 'react'

const FetchContext = createContext({
  fetchData: () => Promise.reject('fetchData function not implemented'),
})

export const FetchProvider = ({ children }) => {
  const promisesRef = useRef({})
  const abortControllerRef = useRef(new AbortController())

  useEffect(() => {
    return () => {
      abortControllerRef.current.abort() // Cleanup on unmount
    }
  }, [])

  const fetchData = useCallback((endpoint, username) => {
    const key = endpoint.replace('{}', username)

    if (!promisesRef.current[key]) {
      const { signal } = abortControllerRef.current

      promisesRef.current[key] = fetch(`${import.meta.env.PUBLIC_API_URL}${key}`, { signal })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`Network response was not ok. Response Code: ${response.status}`)
          }
          return response
        })
        .then((response) => response.json())
    }

    return promisesRef.current[key]
  }, [])

  const resetData = useCallback(() => {
    promisesRef.current = {}
    abortControllerRef.current.abort()
    abortControllerRef.current = new AbortController()
  }, [])

  return <FetchContext.Provider value={{ fetchData, resetData }}>{children}</FetchContext.Provider>
}

export default FetchContext
