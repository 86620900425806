import { useEffect, useContext } from 'react'
import CategoryList from '../components/CategoryList'
import debounce from '../utils/debounce'
import ConfigContext from '../contexts/ConfigContext'
import FetchContext, { FetchProvider } from '../contexts/FetchContext'
import StarContext, { StarProvider } from '../contexts/StarContext'
import styles from '../styles/QueryPage.module.css'

function QueryPage({ typingState, username, scrollPositionRef }) {
  const { categories } = useContext(ConfigContext)
  const { resetData } = useContext(FetchContext)
  const { starredPlatforms } = useContext(StarContext)
  const starredPlatformKeys = Object.keys(starredPlatforms)

  useEffect(() => {
    window.scrollTo(0, scrollPositionRef.current)

    const debouncedSetPosition = debounce((position) => {
      scrollPositionRef.current = position
    }, 100)

    const handleScroll = () => {
      debouncedSetPosition(window.scrollY)
    }
    window.addEventListener('scroll', handleScroll)

    return () => {
      resetData()

      debouncedSetPosition.flush() // apply any pending debounced position updates
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    if (typingState) {
      resetData()
    }
  }, [typingState])

  return (
    <div aria-busy={typingState} className={styles['query-page']}>
      {starredPlatformKeys.length > 0 && (
        <div className={styles['full-row']}>
          <CategoryList
            categoryName="Starred"
            platformKeys={starredPlatformKeys}
            username={username}
            typingState={typingState}
            showMoreEnabled={false}
            starredCategory
          />
        </div>
      )}
      {Object.entries(categories).map(([category, { list }]) => (
        <CategoryList
          key={category}
          categoryName={category}
          platformKeys={list}
          username={username}
          typingState={typingState}
        />
      ))}
    </div>
  )
}

export default function QueryPageWithFetchProvider(props) {
  return (
    <FetchProvider>
      <StarProvider>
        <QueryPage {...props} />
      </StarProvider>
    </FetchProvider>
  )
}
