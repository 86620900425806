import { useState, useRef, useEffect } from 'react'
import styles from '../../styles/utility/Tooltip.module.css'

const isTouchDevice = () => {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0
}

const Tooltip = ({ children, text }) => {
  const [isVisible, setIsVisible] = useState(false)
  const tooltipRef = useRef(null)

  const toggleVisibility = () => {
    setIsVisible((currentVisibility) => !currentVisibility)
  }

  useEffect(() => {
    const tooltipEl = tooltipRef.current

    if (isTouchDevice()) {
      const handleClickOutside = (event) => {
        if (tooltipEl && !tooltipEl.contains(event.target)) {
          setIsVisible(false)
        }
      }
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }

    const handleMouseEnter = () => setIsVisible(true)
    const handleMouseLeave = () => setIsVisible(false)

    tooltipEl.addEventListener('mouseenter', handleMouseEnter)
    tooltipEl.addEventListener('mouseleave', handleMouseLeave)

    return () => {
      tooltipEl.removeEventListener('mouseenter', handleMouseEnter)
      tooltipEl.removeEventListener('mouseleave', handleMouseLeave)
    }
  }, [])

  return (
    <div
      ref={tooltipRef}
      className={styles.tooltip}
      onClick={(e) => {
        e.preventDefault()
        if (isTouchDevice()) {
          toggleVisibility()
        }
      }}
    >
      {children}
      {isVisible && <span className={styles.tooltiptext}>{text}</span>}
    </div>
  )
}

export default Tooltip
