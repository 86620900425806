export const platformResults = {
  available: 'available',
  loading: 'loading',
  error: 'error',
  unknown: 'unknown',
  taken: 'taken',
  invalid: 'invalid',
}

export const resultMessages = {
  [platformResults.available]: 'Available',
  [platformResults.loading]: 'Loading...',
  [platformResults.error]: 'Error',
  [platformResults.unknown]: 'Unknown',
  [platformResults.taken]: 'Taken',
  [platformResults.invalid]: 'Invalid',
}
