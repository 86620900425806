import { useState, useEffect, useRef, useContext } from 'react'
import useIntersectionObserver from '../hooks/useIntersectionObserver'
import FetchContext from '../contexts/FetchContext'
import ConfigContext from '../contexts/ConfigContext'
import { useStar } from '../contexts/StarContext'
import styles from '../styles/ListItem.module.css'
import PlatformWithIcon from './utility/PlatformWithIcon'
import PlatformResult from './utility/PlatformResult'
import FavButton from './utility/FavButton'
import { platformResults } from '../constants'
import newTabIcon from '../assets/new-tab.svg'

export default function ListItem({ platformName, username, typingState }) {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState({})
  const activeFetchId = useRef(0)
  const [itemRef, entry] = useIntersectionObserver()
  const { fetchData: fetchDataCallback } = useContext(FetchContext)
  const { platforms } = useContext(ConfigContext)
  const [starred, toggleStar] = useStar(platformName)

  if (!platforms[platformName]) {
    console.error(`Error: ${platformName} is undefined in platforms`)
    if (starred) {
      // If platform is undefined, unstar it in case it was previously starred
      toggleStar()
    }

    return null
  }

  const { url: endpoint } = platforms[platformName]

  useEffect(() => {
    if (!entry?.isIntersecting) {
      return
    }

    if (typingState) {
      // If user is typing, reset the fetch state
      activeFetchId.current++
      setLoading(true)
      return
    }

    const fetchData = async () => {
      const fetchId = activeFetchId.current
      setLoading(true)

      try {
        const res = await fetchDataCallback(endpoint, username)
        if (fetchId === activeFetchId.current) {
          // Only update state if this is the most recent fetch
          setData(res)
          setLoading(false)
        }
      } catch (error) {
        if (fetchId === activeFetchId.current) {
          console.error(error)
          setData({ error: true })
          setLoading(false)
        }
      }
    }

    fetchData()
  }, [typingState, entry])

  let finalResult = data.result
  let statusClass = ''

  if (loading) {
    finalResult = platformResults.loading
    statusClass = styles.loading
  } else if (data.error) {
    finalResult = platformResults.error
    statusClass = styles.error
  } else if (data.result === 'unknown') {
    statusClass = 'unknown'
  } else if (data.result === 'available') {
    statusClass = styles.available
  } else if (data.result === 'taken') {
    statusClass = `${styles.taken} ${styles.disabled}`
  } else if (data.result === 'invalid') {
    statusClass = `${styles.invalid} ${styles.disabled}`
  }

  return (
    <div ref={itemRef} className={`${styles.container} ${statusClass}`}>
      <a
        className={`${styles.platformWrapper} ${styles.hoverable}`}
        href={!loading ? data.url : undefined}
        rel="noopener noreferrer"
        target="_blank"
      >
        <div className={styles.platformWithIcon}>
          <PlatformWithIcon
            platformName={platformName}
            linethrough={!loading && ['taken', 'invalid'].includes(data.result)}
          />
          <img src={newTabIcon.src} alt="" aria-hidden="true" className={styles.newTabIcon} />
        </div>
        <PlatformResult result={finalResult} />
      </a>
      <div className={styles.hoverable}>
        <FavButton platformName={platformName} />
      </div>
    </div>
  )
}
